
import { Component, Vue } from 'vue-property-decorator'
import { Services } from 'tradingmate-modules/src/services'

@Component
export default class QuoteRequestSearchCancelForWebmaster extends Vue {
  private loading = false
  private submissionSuccessful = false

  mounted (): void {
    this.loading = true
    var quoteId = this.$route.params.id
    if (!quoteId) {
      console.log('Failed to get quoteId')
    }
    Services.API.Quotes.ResolveSearch(quoteId)
      .then(() => {
        this.submissionSuccessful = true
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
